//Primer function for openai model

export default function SetPrimer({nextStep}) {
    return (
        <div className="primer col-auto">
            <h5 className="card-title text-secondary text-center">Awesome -;)</h5>
            <h6 className="card-subtitle mb-2 text-secondary text-center">Tell me about yourself, so I can help you better.</h6>
            <div className="vstack gap-2 col-auto">
                <button onClick={() => nextStep(1)} className="btn btn-outline-info" type="button">I am a Visitor on the island</button>
                <button onClick={() => nextStep(2)} className="btn btn-outline-info" type="button">I am planning a trip to the island</button>
                <button onClick={() => nextStep(3)} className="btn btn-outline-info" type="button">I am a Resident on the island</button>
            </div>
        </div>
    )
}