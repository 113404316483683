//Welcome component

export default function Welcome({nextStep}) {
    return (
        <div className="intro col-auto text-center">
            <h5 className="card-title text-secondary">Hi there!</h5>
            <h6 className="card-subtitle mb-2 text-secondary">I'm your personal Concierge in Mauritius</h6>
            <p className="card-text text-secondary mt-3">
            I can't wait to help you.<br></br>
            I am powered by AI, and my assistance is limited to the information I have been learning from.<br></br>
            When using my service you agree that the owners and operators of this app cannot be held liable for the usefulness, correctness, appropriateness or 
            accuracy of the information I am providing you.
            </p>
            <button onClick={nextStep} className="btn btn-outline-info" type="button">I understand</button>
        </div>
    )
}