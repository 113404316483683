import './App.css';
import {useState} from 'react';
import Welcome from './components/Welcome';
import SetPrimer from './components/SetPrimer';
import OpenAi from './components/OpenAi';

function App() {
  const [step, setStep] = useState(1);
  const [primer, setPrimer] = useState(0);

  function nextStep (primerChoice=0) {
      if (primerChoice > 0) {
          setPrimer(primerChoice);
      }
      if (step >= 3) {
          setStep(1);
      } else {
        setStep(step + 1);
      }
  }

  function showContent () {
    switch (step) {
        case 2:
            return <SetPrimer nextStep={nextStep} />;
        case 3:
            return <OpenAi primer={primer} />;
        default:
            return <Welcome nextStep={nextStep} />;
    }
  }

  return (
    <div className="container bg-light">
      <div className="row align-items-center justify-content-center">
          <div className="col-md-6">
              <div className="card m-3">
                  <div className="card-body d-flex row align-items-center justify-content-center">
                      {showContent()}
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default App;
