// OPenAi chat component
import {useState} from 'react';
import axios from 'axios';
import Conversation from './Conversation';

export default function Chatbot({primer, nextTask}) {
    const [prompt, setPrompt] = useState('');
    const [conversation, setConversation] = useState([{prompt:'', response:''}]);
    const [loading, setLoading] = useState(false);
    const subtitle = getSubtitle(primer);

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post("https://api.n3connect.com/openai/", {primer: primer, prompt: prompt, conversation: conversation});
            //console.log(response.data.conversation);
            setConversation(response.data.conversation);
        } catch (error) {
            //console.error(error.response.data);
            setConversation('Ooops. Something has gone wrong.')
        }
        setLoading(false);
    }
    
    return (
        <div className="chatbot col text-center">
            <h5 className="card-title text-secondary">I'm ready</h5>
            <h6 className="card-subtitle mb-2 text-secondary">{subtitle}</h6>
            <Conversation conversation={conversation} />
            <form className="row align-items-center justify-content-center" onSubmit={handleSubmit}>
                <div className="col-md-8">
                    <textarea className="form-control" type="text" value={prompt} onChange={(e) => setPrompt(e.target.value)} placeholder="How can I assist?"></textarea>
                </div>
                <div className="m-1 col">
                    { loading ? 
                        <button className="btn btn-info" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                             Thinking...
                        </button>
                        :
                        <button className="btn btn-sm btn-info" disabled={prompt.length === 0} type="submit">Ask me</button>
                    }
                    
                </div>
            </form>
        </div>
    )
}

function getSubtitle(primer) {
    switch (primer) {
        case 1:
            return ('to help you exploring Mauritius');
        case 2:
            return ('to help you planning your trip to Mauritius');
        case 3:
            return ('to help you making life easier in Mauritius');
        default:
            return ('Ooops. Something has gone wrong.');
    }    
}