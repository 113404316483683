//Conversation display component

export default function Conversation ({conversation}) {
    const conversationList = conversation.map((item, index) => {
        return (
            <li key={index} className="list-group-item">
                <div className="d-flex flex-row flex-wrap">
                    <span className="badge rounded-pill bg-info me-2">You</span>
                    <div className="fs-6 fw-light text-wrap text-start">{item.prompt}</div>
                </div>
                <div className="mt-1 d-flex flex-row flex-wrap">
                    <span className="badge rounded-pill bg-secondary me-2">AI Concierge</span>
                    <div className="fs-6 fw-light text-wrap text-start">{item.response}</div>
                </div>
            </li>
        )
    });

    return (
        <>
            { conversation[0].prompt !== '' ? 
                <div className="row mt-2 d-flex align-items-top justify-content-start">
                    <div className="col-auto">
                        <ol className="list-group mb-2">
                            {conversationList}
                        </ol>
                    </div>
                </div>
            : <div></div>}
        </>
    )
}